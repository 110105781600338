// 
//   General content styles
// ==========================================================================

html, button, input, select, textarea {
  color: $dark;
}

body {
  background: $light;
  color: $dark;
  line-height: 1.4;
  font-size: 100%;
  font-family: $base-font;  
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 { 
  font-family: $fancy-font; 
}

h1, .h1 {
  color: $rra_blue;
  font-weight: 400;
  font-size: 36px;
}

h2, .h2 {
  color: $dark; 
  font-weight: 700;
  font-size: 32px;
}

h3, .h3 {
  color: $rra_blue; 
  font-weight: 400;
  font-size: 28px;
}

h4 {
  color: $dark; 
  font-weight: 400;
  font-size: 24px;
}

h1 a, a h1, h2 a, a h2, h3 a, a h3 {   
  text-decoration: none; 
  border-bottom: 0; 
}


a { color: #000; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

iframe, object, embed {
  max-width: 100%;
}

nav ul { 
  list-style: none; 
  li { 
    display: inline-block; 
    line-height: 1.2em;
    padding: 5px;
  }
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

*, :before, :after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}
