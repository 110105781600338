//Include normalize.cs
@import "partials/normalize";
//Setup colors, variables, and mixins
@import "partials/variables-mixins";
//col-x-x grid stuff
@import "partials/grid";
//baseline styles and defaults
@import "partials/global";

//
// Buttons, Readmores, Misc
// ==========================================================================

.readmore {
  color: #006;
  font: 14px/18px $fancy-font;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
}

.btn,
.contact-form input[type="submit"]  {
  display: inline-block;
  padding: 10px 15px;
  border: 2px $light_blue solid;
  border-radius: 1px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
  background: $rra_blue;
  text-decoration: none;
  &:hover{
    background-color: lighten($rra_blue, 10%);
  }
}

button.search-submit {

  border: 1px solid $light_blue;
  background-color: $rra_blue;
  color: #FFF;
  text-transform: uppercase;
  padding: 10px 20px;
  margin: 20px;

  &:hover{
    background-color: lighten($rra_blue, 10%);
  }

  @include breakpoint(medium) {
    background-image: url('../img/search-icon-blue.svg');
    background-repeat: no-repeat;
    background-color: transparent;
    border: 0;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;

    &:hover{
      background-color: transparent;
    }

    .submit-text {
      display:none;
    }
  }
  
}


//
// Pages
// ==========================================================================

.entry-title { margin-top: 0; }

// add padding to top when alert is displayed 
.front-page-intro {
  transition: padding-top 0.3s ease;
  &.alert {
    padding-top: 100px; 
    
    @include breakpoint(782px, min) {
      padding-top: 80px 
    }
  }
}

.single-campus_location .main-content {
  transition: padding-top 0.3s ease;
  &.alert {
    padding-top: 120px; 
    
    @include breakpoint(782px, min) {
      padding-top: 80px 
    }
  }

}

article {
  ul {
    padding-left: 25px;
  }
}

.page {
  h1.entry-title {
    color: #000;
  }
}

.contact-form {
  input[type="submit"] {

  }
}

.gform_confirmation_message {
  font-weight: 700;
}

//
// Front Page
// ==========================================================================

.front-page-intro {
  position:relative;
  color: #FFF;
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 40px;

  background: linear-gradient(
      to right top,
      rgba(29, 58, 115, 0.7), 
      rgba(4, 110, 109, 0.9)
    ), url('../img/river-rock-bg.jpg') no-repeat;

  background-size: cover; 

  @include breakpoint(medium) {
    text-align: center;
  }

  @include breakpoint(large) {
    padding-right: 0;
    padding-left: 0;  
  }

  &>.inner-content-wrap {
    padding: 20px 0;
  }

  h2, h3 {
    
    color: $beige;
    margin: 0;
    .top-level {
      color: #FFF;
      font-size: 54px;
      display:block;
      font-weight: 400;
    }

  }

  h2 {
    font-size: 48px;
    line-height: 1.2;
    @include breakpoint(medium) {
      font-size: 80px; 
      max-width: 75%;
      margin-left: auto;
      margin-right: auto; 
    }

    @include breakpoint(large) {
      max-width: 100%;  
    }

    .top-level {
      font-size: 35px;

      @include breakpoint(medium) {
        font-size: 54px;
      }
    }
    
  }
}

.front-page-intro-description.inner-content-wrap {
  padding:0;

  h3 {
    font-size: 36px;
  }

  p {
    @include breakpoint(medium) {
      max-width: 75%;
      margin-left: auto;
      margin-right: auto;
    }

    @include breakpoint(large) {
      max-width: 100%;  
    }
    
  }
}

.video-placeholder-wrap {
  position: relative;
  max-width: 520px;
  margin: 20px auto;
}

.modal-btn {
  background: transparent;
  border: 0;

  &:hover {
    img {
      opacity: .8;

    }

    &:after {
      opacity: .8;
    }
  }

  &:after {
    content: url('../img/play-button.svg');
    width: 48px;
    height: 48px;
    background: transparent;
    border: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


.fp-middle-content {
  flex-direction: column-reverse;

  @include breakpoint(large) {
    flex-direction: row;
  }
}

article.content.content-frontpage,
.link-blocks {
    width: 100%;
    
    @include breakpoint(large) {
      width: 50%;  
    }
}

article.content.content-frontpage {
  padding: 20px;
  h2:first-of-type {
    margin-top: 0;
  }
}

.link-blocks {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;

  .link-block-wrap {
    padding: 10px;
    display: flex;
  }

  .link-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $rra_blue;
    text-decoration: none;
    height: 150px;

    @include breakpoint(medium) {
      height:330px;  
    }
    
    @include breakpoint(large) {
      height: auto;
    }
    
    .link-block-text {
      color: $beige;
      font-family: $fancy-font;
      text-align: center;
      font-size: 24px;
      transition: all .2s ease-in-out;
    }

    &:hover {
      .link-block-text {
        transition: all .2s ease-in-out;
        transform: scale(1.2);
      }
    }
  }
}

.fp-news-section-wrap {
  margin: 10px auto;
  flex-direction: column;
  display:none;

  @include breakpoint(medium) {
      display: flex;
  }

  @include breakpoint(large) {
    margin: 40px auto;
    flex-direction: row;  
  } 

  .fp-blog-image-wrap.col-1-2,
  .fp-featured-blog.col-1-2 {
    width: 100%;
    @include breakpoint(large) {
      width: 50%; 
    }
  }

  .fp-blog-image-wrap {
    padding: 20px;
    img {
      width: 100%;
    }

    display: none;

    @include breakpoint(large) {
      display: block;
    }
  }

  .fp-featured-blog {
    display: none;

    @include breakpoint(medium) {
      display: block;

      .newsletter-link {
        text-decoration: none;
        display: block;
        margin: 10px 0;
      }

    }

    .newsletter-title-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 10px 5px 10px;
      background: linear-gradient(0deg, rgba(240,223,156,1) 0%, rgba(240,223,156,0) 100%);

      img.newsletter-logo {
        margin-right: 30px;
      }

      h3 {
        font-size: 36px;
        line-height: 1em;
      }

      p {
        font-size: 20px;
        line-height: 24px;
      }

      h3, p {
        margin: 0;
        color: #000;
      }

      .download-text {
        margin-top: 10px;
      }
    }
  }

  .fp-featured-blog {
    .fp-featured-blog-wrap {
      padding: 0 10px 0 0;
      margin: 10px 0;
      .col-1-3 {
        @include breakpoint(600px, min) {
          width: 33.33%;
        }
      }

      .col-2-3 {
        @include breakpoint(600px, min) {
          width: 66.66%;
        }
      }

      a.fp-blog-link {
        text-decoration: none;
      }
    }
    .fp-featured-blog-title {
      font-weight: 700;
      color: #000;
      margin-top: 0;
      margin-left: 10px;
      margin-bottom: 10px;
    }

    .fp-blog-title {
      font-size: 20px;
      margin: 0;
      border-bottom: 2px solid #636363;
      padding-bottom: 10px;

      a {
        &:hover {
          color: $rra_blue;
        }
      }
    }
    .fp-blog-content p {
      margin: 10px 0;
    }

    a.read-more { 
      color: $light_blue; 
      font-style: italic;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

//
// // Styling the Modal window
//


.modal-content-wrap {
  max-width: 800px;
  margin: 20px auto;
  position: relative
}

.video-wrap {
  position: relative; 
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  margin: 20px auto;
}

.video-wrap iframe,
.video-wrap object,
.video-wrap embed { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.mfp-close-btn-in .mfp-close,
.mfp-close {
  color: #FFF !important;
  top: -40px;
  cursor: pointer !important;

  &:active {
    top: -40px; 
  }
}

.mfp-content {
  padding: 0 20px;

  @include breakpoint(large) {
    padding: 0;
  }
}


// Accordion
//  

.accordion-item-wrap {
  padding: $xsm-sp 0;
  border-bottom: 2px solid;
  &:first-of-type {
    border-top: 2px solid;
  }

}

.faq-accordion__panel[aria-hidden=true] {
  display: none;
}

.faq-accordion__header {
  display: block;
  margin: 10px 0;
  color: #000;
}

button.accordion-header {
  padding: 0 !important;
  border: 0;
  background: none;
  text-align: left;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  // margin-left: 25px;
  // position: relative;
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  justify-content: space-between;
}

h3.faq-accordion__title {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
} 

/* title opened */
.faq-accordion__header[aria-expanded="true"]:before {
  content: url("../img/accordion-close.svg");
}
/* title closed */
.faq-accordion__header[aria-expanded="false"]:before {
  // content: "+ ";
  content: url("../img/accordion-open.svg");
}

.faq-accordion__header[aria-expanded="true"]:before,
.faq-accordion__header[aria-expanded="false"]:before {
  height: 40px;
  width: 40px;
}


.faq-accordion__panel {
 display: block;
 overflow: hidden;
 opacity: 1;
 transition: visibility 0s ease, max-height .5s ease, opacity .5s ease ;
 max-height: 5000em;
 /* magic number for max-height = enough height */
 visibility: visible;
 transition-delay: 0s;
 margin: 0;
 padding: 0;
}
/* This is the hidden state */
[aria-hidden=true].faq-accordion__panel {
 display: block;
 max-height: 0;
 opacity: 0;
 visibility: hidden;
 transition-delay: .5s, 0s, 0s;
 margin: 0;
 padding: 0;
}

.video-accordion-section {
  .wysiwyg-section {
    p:first-of-type {
      margin-top: 0;
    }
  }
}

//
// Archive - Campus Locations 
//

.upper-campus-archive {
  padding: 0 20px;
  h1.entry-title {
    margin-bottom: 10px;
  }
  p {
    margin: 0;
  }
}

.campus-archive {
  color: #FFF;
  padding: 0 10px;
  margin: 20px auto;
  
  .campus-archive-article {
    display: flex;
    color: #FFF;
    width: 100%;
    justify-content: center;

    @include breakpoint(medium) {
      width:50%;  
      justify-content: left;
    }

    @include breakpoint(large) {
      width:25%; 
      
    }
  }

  .campus-archive-block {
    display: block;
    background: $dark_blue;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    width: 100%;

    &:hover {
      img {
        transition: opacity .25s linear;
        opacity: .8;
      }
    }

    img.slide-image {
      display:none;

      @include breakpoint(medium) {
        display:inline-block;  
      }
    }

    .campus-archive-block-title {
      color: #FFF;
      margin: 0;
      font-size: 24px;
      font-weight: 400;
    }

    .campus-archive-block-sub-block {
      padding: 10px;
    }

    .campus-archive-address {
      color: #FFF;
      font-size: 14px;
    }
  }
}


//
// Single Campus 
// ========================================================================

.campus-content-block {
  .rra-intro-title {
    font-size:24px;
    font-family: $fancy-font;
  }
  
  h2 {
    margin: 10px 0;
  }

  .campus-title {
    line-height: 1;
    font-weight: 700;
  }
}

ul.campus-service-list {
  font-size: 24px;
  font-family: $fancy-font;
  margin: 10px 0;
  color: $dark_blue;

  .service-item {
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}


.accordion-content {
  .handbook {
    display: flex;
    
    a.handbook-link {
      display: flex;
      flex-direction: row-reverse;
      text-decoration: none;
      transition: all .25s ease-in-out;

      &:hover {
        
        h4 {
          text-decoration: underline; 
        }

        img {
          opacity: .8;
          transition: all .25s ease-in-out;
        }
      }
    }  

    h4 {
      margin: 10px 5px;
      transition: all .25s ease-in-out;
    }

    &:nth-of-type(odd) {
      @include breakpoint(medium) {
        padding-left: 0;
      }
    }
    
    &:nth-of-type(even) {
       @include breakpoint(medium) {
        padding-right: 0; 
      }
    }
  }
}
   

.campus-sections {
  padding: 20px 0 40px 0;
}

.staff-sub-section {
  margin: 20px auto;
  @include breakpoint(large) {
    padding-left: 50px; 
  }
}

.campus-staff-section {
  h3 {
    margin: 0;
  }
}

.campus-block {
  border-bottom: 1px solid #707070;

  &:first-of-type {
    border-top: 1px solid #707070;
  }
}

.staff-member {
  width: 100%;
  margin: 20px 0;
  @include breakpoint(medium) {
     width: 50%; 
  }
}

.staff-name {
  margin: 0;
}

.staff-title {
  margin: 0;
  font-weight: 700;
  // font-size: 14px;
}

.campus-location-block {
  h2 {
    font-weight: 700;
    color: #000;
    margin: 15px 0 10px 0;
  }
  .campus-address {
    font-size: 14px;
  }
}

.campus-calendar-content {
  padding: 20px 0;

  a.calendar-link {
    &:hover {
      text-decoration: none;
    }
  }
}

// FAQ 

.faq-section-wrap.wrap {
  padding: 0;
}

.faq-section {
  margin: 40px 0;
}

//
// Single posts
// ==========================================================================

.educational-services-wrap {
  margin: 20px 0; 
  @include breakpoint(large) {
    margin: 40px 0;   
  }
}

.page-banner-image-wrap {
  img {
    width: 100%;
  }
}

article.single-job-listing,
article.single-blog-listing,
div.single-blog-listing,
div.single-job-listing,
a.single-blog-listing,
a.single-job-listing {
  display: flex;
  margin: 20px 0;

  .single-career-image,
  .single-blog-image {
    display: none;
    @include breakpoint(medium) {
      display: block;  
      width: 25%;
      padding-right: 20px;
      padding-top: 10px; 
    }

    img {
      width: 100%;
    }  
  }

  .single-career-content,
  .blog-content {
    width: 100%;
    @include breakpoint(medium) {
       width: 75%; 
    }
  }
} 

.single-career-page {
  .single-career-page-image {
    img {
      width: 100%;
    }
  }
}

a.single-blog-listing,
a.single-job-listing {
  text-decoration: none;

  &:hover {
    h1 {
      text-decoration: underline;
    }

    img {
      opacity: .8;
    }
  }
}

.flex-wrap.news {
  padding: 0 20px;
}

.news {
  .single-news {
    width: 100%;
    @include breakpoint(large) {
      width: 66.67%;
    }
  }
}

.sidebar {
  h2.widget-title {
    margin-top: 0;
  }
}


.single-post, 
.blog {
  .sidebar {
    display: none;
    @include breakpoint(large) {
      display:block;
    }
  }
}

//
// Footer Styles
// ==========================================================================

.colophon {
  background: $dark_blue;
  color: $light;

  .wrap.footer-wrap {
    padding: 20px;
  }

  .footer-logo-wrap {
    padding-right: 10px;
  }

  .footer-logo {
    width: 80px;

    @include breakpoint(medium) {
      width: 90px;
    }
    @include breakpoint(large) {
      width: 100px; 
    }
  }

  .upper-footer {

    @include breakpoint(large) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
  }

  .footer-logo-section {
    display: flex;
    width: 100%;
    @include breakpoint(large) {
      width: 50%;
    }
  }

  .footer-statement-wrap {
    width: 100%;
    margin-top: 20px;
    @include breakpoint(large) {
      width: 50%;
      text-align: right;
    }

    img {
      max-width: 100%;
      @include breakpoint(large) {
        max-width: 400px;
      }
    }
  }

  a {
    color: #FFF;
    text-decoration: none;
  }

  .footer-heading {
    font-size: 32px;
    display: inline-block;
    color: #FFF;
    font-weight: 700;
    line-height: 1;
    margin: 0;

    br {
      @include breakpoint(medium) {
        display:none;
      }
    }
  }

  br.address-break {
    @include breakpoint(medium) {
      display:none;  
    }
  }

  p.footer-description {
    font-size: 16px;
  }

  p.address {
    font-size: 14px;
  }

  p.footer-description,
  p.address {
    margin: 5px 0px;
  }

  .copyright {
    font-size: 12px;
    margin-top: 20px;
    @include breakpoint(large) {
      width: 65%;
    }
  }
}

@import "partials/mfp";
@import "partials/wordpress-defaults";
@import "partials/helpers-placeholders";
@import "partials/print";